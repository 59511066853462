import {
  Box,
  Button,
  Checkbox,
  CloseButton,
  Group,
  Input,
  Radio,
  TextInput,
} from "@mantine/core";
import { showErrorToast } from "app/components/Notifications/customNotifications";
import {
  Answer,
  AssignmentQuestion,
  QuestionType,
} from "modules/questions/question.types";
import { useCallback, useEffect, useState } from "react";
import { TbPlus } from "react-icons/tb";
import { useDebounceFetcher } from "remix-utils/use-debounce-fetcher";

import { uuidv4 } from "app/utils/ids/uuid";
import { action } from "../api.questions.$questionId.updateAnswerChoices";

const ANSWER_CHOICE_TYPES = [
  QuestionType.MULTIPLE_CHOICE,
  QuestionType.SELECT_ALL,
  QuestionType.TRUE_FALSE,
] as const;

type AnswerChoiceType = (typeof ANSWER_CHOICE_TYPES)[number];

const isAnswerChoiceType = (type: QuestionType): type is AnswerChoiceType =>
  ANSWER_CHOICE_TYPES.includes(type as AnswerChoiceType);

export function AnswerChoicesEditor({
  item,
}: {
  item: AssignmentQuestion["question"];
}) {
  const fetcher = useDebounceFetcher<typeof action>();
  const [answers, setAnswers] = useState<Answer[]>(() => {
    const content = item.questionContent;
    if (isAnswerChoiceType(content.type)) {
      const typedContent = content as Extract<
        typeof content,
        { type: AnswerChoiceType }
      >;
      return typedContent.answerChoices;
    }
    return [];
  });

  const updateBackend = useCallback(
    (newAnswers: Answer[], debounceTimeout?: number) => {
      fetcher.submit(
        JSON.stringify({
          type: item.questionContent.type,
          answerChoices: newAnswers,
        }),
        {
          method: "POST",
          action: `/api/questions/${item.id}/updateAnswerChoices`,
          encType: "application/json",
          debounceTimeout: debounceTimeout ?? 1000,
        },
      );
    },
    [fetcher, item.id, item.questionContent.type],
  );

  const handleAnswerChange = useCallback(
    (
      index: number,
      answerText?: string | null,
      isCorrect?: boolean,
      remove?: boolean,
      debounceTimeout?: number,
    ) => {
      setAnswers((prevAnswers) => {
        let newAnswers = [...prevAnswers];
        if (remove) {
          newAnswers.splice(index, 1);
        } else {
          if (answerText !== undefined) {
            newAnswers[index] = {
              ...newAnswers[index],
              answerText: answerText || "",
            };
          }
          if (isCorrect !== undefined) {
            if (
              (item.questionContent.type === QuestionType.MULTIPLE_CHOICE ||
                item.questionContent.type === QuestionType.TRUE_FALSE) &&
              isCorrect
            ) {
              // For multiple choice and true/false, set all other answers to false
              newAnswers = newAnswers.map((answer, i) => ({
                ...answer,
                isCorrect: i === index,
              }));
            } else {
              newAnswers[index] = { ...newAnswers[index], isCorrect };
            }
          }
        }
        updateBackend(newAnswers, debounceTimeout);
        return newAnswers;
      });
    },
    [updateBackend, item.questionContent.type],
  );

  const addAnswer = useCallback(() => {
    const newAnswer = {
      id: uuidv4(),
      answerText: "",
      isCorrect: false,
    };
    setAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers, newAnswer];
      updateBackend(newAnswers, 0);
      return newAnswers;
    });
  }, [updateBackend]);

  useEffect(() => {
    if (fetcher.state === "idle" && fetcher.data && "error" in fetcher.data) {
      showErrorToast(`Failed to update answers: ${fetcher.data.error}`);
      const content = item.questionContent;
      if (isAnswerChoiceType(content.type)) {
        const typedContent = content as Extract<
          typeof content,
          { type: AnswerChoiceType }
        >;
        setAnswers(typedContent.answerChoices);
      }
    }
  }, [fetcher.state, fetcher.data, item]);

  if (!isAnswerChoiceType(item.questionContent.type)) {
    return null;
  }

  const renderAnswerGroup = () => {
    if (
      item.questionContent.type === QuestionType.MULTIPLE_CHOICE ||
      item.questionContent.type === QuestionType.TRUE_FALSE
    ) {
      return (
        <MultipleChoiceGroup
          answers={answers}
          questionId={item.id}
          handleAnswerChange={handleAnswerChange}
        />
      );
    }
    return (
      <SelectAllGroup
        answers={answers}
        handleAnswerChange={handleAnswerChange}
      />
    );
  };

  // Only show add answer button for multiple choice and select all
  const showAddAnswer = item.questionContent.type !== QuestionType.TRUE_FALSE;

  return (
    <Input.Wrapper label="Answer options" className="pb-2">
      {renderAnswerGroup()}
      {showAddAnswer && (
        <Button
          variant="transparent"
          color="primary"
          className="p-1"
          onClick={addAnswer}
        >
          <TbPlus color="#475569" size={16} /> &nbsp; Add answer
        </Button>
      )}
    </Input.Wrapper>
  );
}

interface MultipleChoiceGroupProps {
  answers: Answer[];
  questionId: number;
  handleAnswerChange: (
    index: number,
    answerText?: string | null,
    isCorrect?: boolean,
    remove?: boolean,
    debounceTimeout?: number,
  ) => void;
}

export function MultipleChoiceGroup({
  answers,
  questionId,
  handleAnswerChange,
}: MultipleChoiceGroupProps) {
  const selectedValue = answers
    .findIndex((answer) => answer.isCorrect)
    .toString();

  return (
    <Radio.Group
      name={`question-${questionId}-answers`}
      value={selectedValue}
      onChange={(value) => {
        answers.forEach((_, index) => {
          handleAnswerChange(
            index,
            undefined,
            index.toString() === value,
            false,
            0,
          );
        });
      }}
    >
      <Group mt="xs" className="pl-2 pt-1">
        {answers.map((answer, index) => (
          <Box key={index} className="flex gap-2 w-full items-center">
            <Radio
              value={index.toString()}
              size="lg"
              color="primary"
              className="w-full items-center justify-center"
              classNames={{
                label: "w-full",
                body: "w-full items-center justify-center",
                labelWrapper: "w-full",
              }}
              label={
                <TextInput
                  value={answer.answerText}
                  className="w-full"
                  onChange={(e) => handleAnswerChange(index, e.target.value)}
                />
              }
            />
            <CloseButton
              onClick={() => handleAnswerChange(index, null, false, true, 0)}
            />
          </Box>
        ))}
      </Group>
    </Radio.Group>
  );
}

interface SelectAllGroupProps {
  answers: Answer[];
  handleAnswerChange: (
    index: number,
    answerText?: string | null,
    isCorrect?: boolean,
    remove?: boolean,
    debounceTimeout?: number,
  ) => void;
}

export function SelectAllGroup({
  answers,
  handleAnswerChange,
}: SelectAllGroupProps) {
  const selectedValues = answers
    .map((answer, index) => (answer.isCorrect ? index.toString() : null))
    .filter((value): value is string => value !== null);
  return (
    <Checkbox.Group
      value={selectedValues}
      onChange={(value) => {
        answers.forEach((_, index) => {
          handleAnswerChange(
            index,
            undefined,
            value.includes(index.toString()),
            false,
            0,
          );
        });
      }}
    >
      <Group mt="xs" className="pl-2 pt-1">
        {answers.map((answer, index) => (
          <Box key={index} className="flex gap-2 w-full items-center">
            <Checkbox
              value={index.toString()}
              className="w-full"
              size="lg"
              color="primary"
              classNames={{
                label: "w-full",
                body: "w-full items-center justify-center",
                labelWrapper: "w-full",
              }}
              label={
                <TextInput
                  value={answer.answerText}
                  className="w-full"
                  onChange={(e) => handleAnswerChange(index, e.target.value)}
                />
              }
            />
            <CloseButton
              onClick={() => handleAnswerChange(index, null, false, true, 0)}
            />
          </Box>
        ))}
      </Group>
    </Checkbox.Group>
  );
}
